dmx.Component('s3-upload-multi', {

    initialData: {
        data: null,
        files: [],
        state: {
            idle: true,
            ready: false,
            uploading: false
        },
        lastError: ''
    },

    attributes: {
        url: {
            type: String,
            default: null
        },

        prop: {
            type: String,
            default: 'url'
        },

        accept: {
            type: String,
            default: null
        },

        autoupload: {
            type: Boolean,
            default: false
        },

        thumbs: {
            type: String,
            default: 'true'
        },

        'thumb-width': {
            type: Number,
            default: 100
        },

        'thumb-height': {
            type: Number,
            default: 100
        }
    },

    methods: {
        abort: function() {
            this.abort();
        },

        reset: function() {
            this.reset();
        },

        select: function() {
            this.input.click();
        },

        remove: function(id) {
            this.remove(id);
        },

        upload: function() {
            this.startUpload();
        }
    },

    events: {
        start: Event, // when starting an ajax call
        done: Event, // when ajax call completed (success and error)
        error: Event, // server error or javascript error (json parse or network transport) or timeout error
        abort: Event, // ajax call was aborted
        success: Event // successful ajax call
    },

    render: function(node) {
        this.$node.addEventListener('dragover', this.onDragover.bind(this));
        this.$node.addEventListener('drop', this.onDrop.bind(this));
        this.$node.addEventListener('click', this.onClick.bind(this));
        
        this.input = document.createElement('input');
        this.input.type = 'file';
        this.input.multiple = true;
        this.input.accept = this.props.accept || '*/*';
        this.input.addEventListener('change', this.onChange.bind(this));

        this.maxRetries = 5;
        this.uploads = [];
        this.ii = 0;

        this.$parse();
    },

    update: function(props) {
        if (this.props.accept != props.accept) {
            this.input.accept = this.props.accept || '*/*';
        }

        if (this.uploads.length) {
            if (this.isUploading()) {
                this.set('state', {
                    idle: false,
                    ready: false,
                    uploading: true
                });
            } else {
                this.set('state', {
                    idle: false,
                    ready: true,
                    uploading: false
                });
            }
        } else {
            this.set('state', {
                idle: true,
                ready: false,
                uploading: false
            });
        }
    },

    isUploading: function() {
        return !!this.uploads.find(function(upload) {
            return upload.info.uploading;
        }, this);
    },

    nextRetry: function(retries) {
        return (this.maxRetries - retries + 1) * 3000;
    },

    _validate: function(file) {
        // simple validation based on accept to filter out unallowed files
        if (this.props.accept) {
            return this.props.accept.split(/\s*,\s*/g).some(function(type) {
                if (type.charAt(0) == '.') {
                    if (file.name.match(new RegExp('\\' + type + '$', 'i'))) {
                        return true;
                    }
                } else if (/(audio|video|image)\/\*/i.test(type)) {
                    if (file.type.match(new RegExp('^' + type.replace(/\*/g, '.*') + '$', 'i'))) {
                        return true;
                    }
                } else {
                    if (file.type.toLowerCase() == type.toLowerCase()) {
                        return true;
                    }
                }

                return false;
            });
        }

        return true;
    },

    onDragover: function(e) {
        e.stopPropagation();
        e.preventDefault();

        e.dataTransfer.dropEffect = 'copy';
    },

    onDrop: function(e) {
        e.stopPropagation();
        e.preventDefault();

        if (!e.dataTransfer) return;

        var files = e.dataTransfer.files;

        if (files.length) {
            var items = e.dataTransfer.items;

            if (items && items.length && items[0].webkitGetAsEntry) {
                this.updateFilesFromItems(items);
            } else {
                this.updateFiles(files);
            }
        }
    },

    onClick: function(e) {
        this.input.click();
    },

    onChange: function(e) {
        this.updateFiles(e.target.files);
        this.input.value = '';
        this.input.type = '';
        this.input.type = 'file';
    },

    onAbort: function(upload, e) {
        upload.info.uploading = false;
        upload.info.uploaded = 0;
        upload.info.percent = 0;
        
        dmx.requestUpdate();

        if (!this.isUploading()) {
            this.dispatchEvent('abort');
            this.dispatchEvent('done');
        }
    },

    onError: function(upload, e) {
        if (upload.url && upload.retries) {
            setTimeout(this.upload3.bind(this, upload), this.nextRetry(upload.retries--));
            return;
        }
        
        if (e instanceof ProgressEvent) {
            e = 'Network error, perhaps no CORS set';
        } else {
            e = e.message ||  e;
        }

        this.set('lastError', e);

        upload.info.uploading = false;
        upload.info.uploaded = 0;
        upload.info.percent = 0;
        upload.info.error = e;

        dmx.requestUpdate();

        if (!this.isUploading()) {
            this.dispatchEvent('error');
            this.dispatchEvent('done');
        }
    },

    onTimeout: function(upload, e) {
        this.onError(upload, 'Execution timeout');
    },

    onLoad: function(upload, e) {
        if (upload.xhr.status >= 500 || upload.xhr.status == 429) {
            if (upload.retries) {
                setTimeout(this.upload3.bind(this, upload), this.nextRetry(upload.retries--));
            } else {
                this.onError(upload, upload.xhr.responseText || upload.xhr.statusText);
            }
        } else if (upload.xhr.status >= 400) {
            this.onError(upload, upload.xhr.responseText || upload.xhr.statusText);
        } else {
            this.remove(upload.file.id);

            dmx.requestUpdate();

            if (!this.isUploading()) {
                if (!this.uploads.length) {
                    this.dispatchEvent('success');
                } else {
                    this.dispatchEvent('error');
                }
                this.dispatchEvent('done');
            }
        }
    },

    onProgress: function(upload, e) {
        upload.info.uploaded = e.loaded;
        upload.info.percent = e.lengthComputable ? Math.ceil(e.loaded / e.total * 100) : 0;

        dmx.requestUpdate();
    },

    resize: function(src, cb) {
        var img = document.createElement('img');

        var tWidth = parseInt(this.props['thumb-width']) || 100;
        var tHeight = parseInt(this.props['thumb-height']) || 100;

        img.onload = function() {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            var sWidth = img.width;
            var sHeight = img.height;

            tWidth = Math.min(tWidth, sWidth);
            tHeight = Math.min(tHeight, sHeight);

            var sRatio = sWidth / sHeight;
            var tRatio = tWidth / tHeight;

            if (sWidth > tWidth || sHeight > tHeight) {
                if (sRatio > tRatio) {
                    sWidth = sHeight * tRatio;
                } else {
                    sHeight = sWidth / tRatio;
                }
            }

            canvas.width = tWidth;
            canvas.height = tHeight;

            var sx = (img.width - sWidth) / 2;
            var sy = (img.height - sHeight) / 2;

            ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, tWidth, tHeight);

            cb(canvas.toDataURL());
        };
        
        img.src = src;
    },

    updateFile: function(file) {
        if (!this._validate(file)) {
            return;
        }

        file.id = ++this.ii;

        var info = {
            id: file.id,
            name: file.name,
            size: file.size,
            type: file.type,
            date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
            data: null,
            uploading: false,
            uploaded: 0,
            percent: 0,
            ready: false,
            error: null,
            dataUrl: null
        };

        if (file.type.indexOf('image/') !== -1 && !file.reader) {
            file.reader = new FileReader();

            file.reader.onload = function(e) {
                info.dataUrl = e.target.result;
                
                if (this.props.thumbs) {
                    this.resize(info.dataUrl, function(dataUrl) {
                        info.dataUrl = dataUrl;
                        info.ready = true;

                        dmx.requestUpdate();
                    })
                } else {
                    info.ready = true;
                }

                dmx.requestUpdate();
            }.bind(this);

            file.reader.readAsDataURL(file);
        } else {
            info.ready = true;
        }

        var upload = {
            retries: this.maxRetries,
            info: info,
            file: file,
            xhr: null
        };

        this.uploads.push(upload);

        this.set({
            files: this.data.files.concat([info]),
            state: {
                idle: false,
                ready: true,
                uploading: false,
                done: false
            }
        });

        if (this.props.autoupload) {
            if (!this.isUploading()) {
                this.dispatchEvent('start');
            }

            this.upload(upload);
        }
    },

    updateFiles: function(files) {
        dmx.array(files).forEach(function(file) {
            this.updateFile(file);
        }, this);
    },

    updateFilesFromItems: function(items) {
        dmx.array(items).forEach(function(item) {
            var entry;

            if (item.webkitGetAsEntry && (entry = item.webkitGetAsEntry())) {
                if (entry.isFile) {
                    this.updateFile(item.getAsFile());
                } else if (entry.isDirectory) {
                    this.updateFilesFromDirectory(entry);
                }
            } else if (item.getAsFile) {
                if (!item.kind || item.kind == 'file') {
                    this.updateFile(item.getAsFile());
                }
            }
        }, this);
    },

    updateFilesFromDirectory: function(directory, path) {
        var reader = directory.createReader();
        var readEntries = function() {
            reader.readEntries(function(entries) {
                if (entries.length) {
                    entries.forEach(function(entry) {
                        if (entry.isFile) {
                            entry.file(function(file) {
                                file.fullPath = path + '/' + file.name;
                                this.updateFile(file);
                            }.bind(this));
                        } else if (entry.isDirectory) {
                            this.updateFilesFromDirectory(entry, path + '/' + entry.name);
                        }
                    }, this);
                }

                readEntries();
            }.bind(this), function(err) {
                console.warn(err);
            }.bind(this));
        }.bind(this);

        readEntries();
    },

    abort: function() {
        this.uploads.forEach(function(upload) {
            if (upload.xhr) upload.xhr.abort();
        });
    },

    reset: function() {
        this.abort();

        this.uploads = [];

        this.set({
            data: null,
            files: [],
            state: {
                idle: true,
                ready: false,
                uploading: false
            },
            lastError: ''
        });
    },

    remove: function(id) {
        var index = this.uploads.findIndex(function(upload) {
            return upload.file.id == id;
        });

        if (index != -1) {
            if (this.uploads[index].xhr) {
                this.uploads[index].xhr.abort();
            }
            this.uploads.splice(index, 1);
            this.data.files.splice(index, 1);
            dmx.requestUpdate();
        }
    },

    startUpload: function() {
        this.dispatchEvent('start');

        this.uploads.forEach(function(upload) {
            this.upload(upload);
        }, this);
    },

    upload: function(upload) {
        if (upload.info && upload.info.uploading) return;

        if (!this.props.url) {
            this.onError('No url attribute is set');
            return;
        }

        this.set({
            state: {
                idle: false,
                ready: false,
                uploading: true,
                done: false
            }
        });

        upload.info.uploading = true;
        
        dmx.requestUpdate();

        upload.xhr = new XMLHttpRequest();
        upload.xhr.onabort = this.onAbort.bind(this, upload);
        upload.xhr.onerror = this.onError.bind(this, upload);
        upload.xhr.ontimeout = this.onTimeout.bind(this, upload);
        upload.xhr.onload = this.upload2.bind(this, upload);
        upload.xhr.open('GET', this.props.url + '?name=' + encodeURIComponent(upload.file.name));
        upload.xhr.send();
    },

    upload2: function(upload) {
        try {
            upload.info.data = JSON.parse(upload.xhr.responseText);
            upload.url = upload.info.data[this.props.prop];
            upload.xhr.onload = this.onLoad.bind(this, upload);
            upload.xhr.upload.addEventListener('progress', this.onProgress.bind(this, upload));
            upload.xhr.setRequestHeader('Content-Type', upload.file.type);
            if (url.indexOf('x-amz-acl=') != -1) {
                // could be improved
                var acl = url.substr(url.indexOf('x-amz-acl=') + 10);
                if (acl.indexOf('&') != -1) acl = acl.substr(0, acl.indexOf('&'));
                upload.xhr.setRequestHeader('x-amz-acl', acl);
            }
            this.upload3(upload);
        } catch (err) {
            this.onError(upload, err);
        }
    },

    upload3: function(upload) {
        try {
            upload.xhr.open('PUT', upload.url);
            upload.xhr.send(upload.file);
        } catch (err) {
            if (upload.retries) {
                console.log('Retry upload', upload);
                setTimeout(this.upload3.bind(this, upload), this.nextRetry(upload.retries--));
            } else {
                console.log('Error in upload', upload, err);
                this.onError(upload, err);
            }
        }
    }

});